<template>
  <div class="flex_column">
    <dog-table
      ref="dogTable"
      id="844d1f41-4e78-4757-8a55-cba3bc0c2ac9"
      :columns="tableColumns"
      :service="getList"
      column-type="selection"
      @selection-change="selectedData = $event"
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <add-class-item-dialog
      ref="addClassItemDialog"
      @confirm="searchData"
    ></add-class-item-dialog>
    <set-products-dialog
      ref="setProductsDialog"
      @confirm="searchData"
    ></set-products-dialog>
  </div>
</template>

<script>
  import classService from '@/api/service/class';
  import addClassItemDialog from './addClassItemDialog.vue';
  import setProductsDialog from './setProductsDialog';

  export default {
    name: 'classManage__rightPart',
    components: { addClassItemDialog, setProductsDialog },
    data() {
      return {
        mainClass: {},
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 135,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <permission-element
                config={[
                  {
                    code: 'editSubClass',
                    callback: () =>
                      this.$refs.addClassItemDialog.openFrame({
                        id: row.id,
                        primaryClassId: this.mainClass.id
                      })
                  },
                  {
                    code: 'setProducts',
                    callback: () =>
                      this.$refs.setProductsDialog.openFrame({
                        id: row.id,
                        companyId: this.companyId
                      })
                  }
                ]}></permission-element>
            )
          },
          { prop: 'name', label: '子分类名', width: 120 },
          {
            prop: 'sort',
            label: '排序',
            width: 50,
            widthGrow: false
          },
          {
            prop: 'img',
            label: '图片',
            width: 80,
            render: (h, { props: { cur } }) =>
              cur ? (
                <el-image
                  style='height: 30px; margin-right: 10px;'
                  src={cur}
                  fit='contain'
                  preview-src-list={[cur]}
                />
              ) : null
          },
          {
            prop: 'enable_cn',
            label: '启用',
            width: 70,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <dog-switch
                value={row.enable_cn}
                onInput={(val) => this.quickOperate(row.id, val)}></dog-switch>
            )
          },
          { prop: 'desc', label: '描述', width: 100 }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addSubClass',
            callback: this.addSubClass
          },
          {
            buttonType: 'danger',
            code: 'deleteSubClass',
            callback: this.deleteSubClass
          }
        ],
        selectedData: [],
        total: 0,
        companyId: null
      };
    },
    methods: {
      getData(mainClass, companyId) {
        this.mainClass = mainClass;
        this.companyId = companyId;
        this.searchData();
      },
      searchData() {
        this.$refs.dogTable.search();
      },
      getList(params) {
        params.primaryClassId = this.mainClass.id;
        if (!this.mainClass.id) {
          this.total = 0;
          return Promise.resolve({ list: [], total: 0 });
        }
        return classService.getSubClassList(params).then((res) => {
          this.total = res.total;
          return res;
        });
      },
      quickOperate(id, val) {
        const transVal = val ? '1' : '0';
        this.$confirm(
          `是否确定${val ? '启用' : '禁用'}所选数据？${
            !val ? '（将解绑对应产品！）' : ''
          }`,
          '提示',
          {
            type: 'warning'
          }
        ).then(() => {
          classService
            .quickOperateSubClass({ id, enable: transVal })
            .then(this.searchData);
        });
      },
      deleteSubClass() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          classService
            .deleteSubClass({
              ids: this.selectedData.map((item) => item.id)
            })
            .then(this.searchData);
        });
      },
      addSubClass() {
        if (!this.mainClass.id) {
          this.$message.error('请先维护一级分类信息！');
          return;
        }
        this.$refs.addClassItemDialog.openFrame({
          primaryClassId: this.mainClass.id,
          total: this.total
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
